import { useFiltersStore } from '~/stores/filtersStore'

export const useFetchRetailerByLocation = async (
  latitude: number,
  longitude: number,
  isNew: boolean,
  quantity: number = 1
) => {
  const { $api } = useNuxtApp()
  const filtersStore = useFiltersStore()

  const { updateRetailer, saveFilters } = useFilters(filtersStore.active)

  const { data } = await $api.retailers.searchNearest({
    Radius: {
      Lat: latitude,
      Lon: longitude,
      Distance: 100
    },
    CalculateDriveTime: false
  })

  const retailers = data?.Retailers.filter(
    (retailer) =>
      isRetailerAvailable(retailer) && isValidRetailer(retailer, isNew)
  )

  if (quantity === 1 && retailers) {
    const retailer = retailers[0]

    if (!retailer) return retailers

    const preferred = useLocalStorage('preferredRetailerId', 0)

    preferred.value = retailer.Id

    updateRetailer(retailer)
    saveFilters()
  }

  return retailers?.splice(0, quantity)
}
