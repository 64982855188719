export const useGeolocator = (
  onSuccess: PositionCallback,
  onError?: PositionErrorCallback,
  options?: Partial<PositionOptions>
) => {
  const { $dataLayer } = useNuxtApp()
  const preference = useState<string>(
    'geolocationPreference',
    () => 'not chosen'
  )

  const isSupported = useSupported(
    () => navigator && 'geolocation' in navigator
  )

  const successCallback = (position: GeolocationPosition) => {
    $dataLayer.linkClick({
      action: 'browser_geolocation:accept'
    })

    preference.value = 'allowed'

    onSuccess(position)
  }

  const errorCallback = (error: GeolocationPositionError) => {
    $dataLayer.linkClick({
      action: 'browser_geolocation:block'
    })

    preference.value = 'denied'

    onError && onError(error)
  }

  const positionOptions: PositionOptions = {
    maximumAge: Infinity,
    enableHighAccuracy: false,
    timeout: 27000,
    ...options
  }

  const { isEnabled } = useFeatureFlag('geolocation_enabled')

  onMounted(() => {
    if (!isSupported.value || !isEnabled.value) {
      onError &&
        onError({
          code: 2,
          message: 'Geolocation is not supported'
        } as GeolocationPositionError)

      return
    }

    navigator.geolocation.getCurrentPosition(
      successCallback,
      errorCallback,
      positionOptions
    )
  })
}
